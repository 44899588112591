.BackGround {
  display: flex;  
  width:  100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  text-align: center;  
  justify-content: space-between;
  background: linear-gradient( 20deg, rgb(0,2,58) 0%, rgb(16,158,154) 100%);  
}

.Canvas {
  display: flex;  
  width:  min(100vw, 500px);
  height: min(100vh, 800px);  
  background-color: whitesmoke;
  box-shadow: 0 2px 4px 1px rgba(105, 105, 105, .3);
}

.App {
  overflow: hidden;
  display: flex;  
  width:  100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;  
  justify-content: space-between;  
}

.iFrame-card {
  padding: 20px;
  background: rgb(16,158,154);
  width: 290px;
  height: 390px;
  box-shadow: 0 2px 4px 1px rgba(105, 105, 105, .3);
}

.iFrame-auth {
  padding: 10px;  
  width: 100%;
  height: 520px;
  box-shadow: 0 2px 4px 1px rgba(105, 105, 105, .3);
}

.App button:focus,
.App a:focus {
    outline: none; 
}

.Loading {
  display: flex;
  justify-content: center;  
}

.install-or-add {
  background: white;
  width: min(80%,300px);  
  border-radius: 10px;  
  display: flex;
  flex-direction: column;  
  align-items: center;
}

.do-not-install {
  background: rgb(16,158,154);
  color: white;
  width: min(80%,300px);  
  border-radius: 10px;  
  display: flex;
  flex-direction: column;  
  align-items: center;
}

.Main {
  display: flex;  
  flex-direction: column;
  align-items: center;
  text-align: center;  
  justify-content: center;
}

.Main-tickets-table {
  display: flex;  
  flex-direction: column;
  align-items: center;
  text-align: center;  
  justify-content: center;
  height: 90%;
  width: 90%;
}

.Header {
  display: flex;  
  width:  100%;  
  flex-direction: row;
  align-items: center;
  text-align: center;  
  justify-content: space-between;  
}

.Login {
  width: min(70vw, 300px);
  display: flex;  
  flex-direction: column;
  align-items: center;
  text-align: center;  
  justify-content: center;
}

.Register {
  width: min(70vw, 300px);
}

.Promos {  
  width: 80%;
  max-height: 80%;
  overflow: auto;
  display: flex;  
  flex-direction: column;
  align-items: center;
  text-align: center;  
  justify-content: center;
}

.Payment {
  width: min(70vw, 300px);
}

.basic-text-field {
  display: flex;  
  justify-content: center;  
  width: 100%;
  margin-top: 10px;
}

.basic-button-field {
  display: flex;  
  justify-content: center;  
  width: 100%;
  margin-top: 10px;  
}

.buttons-horitzontal {
  display: flex;  
  flex-direction: column;
  justify-content: center;
}

.basic-text-field-max {
  display: flex;
  justify-content: center;  
  width: 200%;  
  margin-top: 10px;  
}

.basic-message {
  display: flex;  
  justify-content: center;  
  width: 80%;    
}

.basic-message-button {
  display: flex;  
  justify-content: center;  
  width: 80%;  
}

.fares-box {
  display: flex;  
  justify-content: center;  
  width: 100%;
  margin-top: 10px;
  padding-inline: 10px;
}

.Deposit-card {  
  width: 250px;
  margin: 5px;  
}

.basic-qr-field {
  display: flex;  
  justify-content: flex-start;  
  width: 100%;  
}

.qr-image {
  display: flex;  
  justify-content: center;  
  width: 60%;
  margin: 5%;
}

.credit-card {
  display: flex;  
  justify-content: center;  
  width: 100%;    
  padding: 15px;
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Avatar {
  display: flex;    
  justify-content: flex-end;    
  width: 100%;
}

.ilock-app-logo {  
  width: 100%;  
  padding-bottom: 10%;
}

.login-textfield-email {  
  width: 100%;  
  background-color: white;
}

.login-textfield-password {  
  width: 100%;  
  background-color: white;
}

.deposit-time {  
  display: flex;  
  justify-content: center;
  align-items: flex-end;
  width: 100%;  
}

.deposit-price {  
  display: flex;  
  justify-content: center;
  align-items: flex-end;
  width: 100%;  
}

.deposit-price-small {  
  display: flex;  
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 5px;
}

.station-info {    
  margin-bottom: 10px;
}

.card-button {  
  display: flex;  
  justify-content: center;
}

.languge-selector {  
  display: flex;  
  justify-content: space-between;  
  margin-top: 10%;
  margin-bottom: 5%;
  margin-inline: 10%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}